<template>
  <transition :name="animationClass" appear>
    <div class="fh-modal-backdrop" @click.self.stop="shouldCloseFromBackDrop && $emit('close')">
      <div :class="['fh-modal', sizeClass]">
        <header :class="headerClass" @click.stop>
          <slot name="header">
            <div>
              <i v-if="hasBackButton" class="fh fh-arrow fh-modal__back-btn" @click.self.stop="$emit('close')"></i>
            </div>
            <div class="fh-modal__title">{{ title }}</div>
            <div>
              <i v-if="hasCloseButton" class="fh fh-close fh-modal__close-btn" @click.self.stop="$emit('close')" data-test="closeModal"></i>
            </div>
          </slot>
        </header>
        <section :class="bodyClass" v-if="hasBody" @click.stop>
          <slot name="body"></slot>
        </section>
        <footer class="fh-modal__footer" v-if="hasFooter">
          <slot name="footer"></slot>
        </footer>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { debounce } from 'lodash-es';
import { setDocumentStyle } from '@/logic/dom/helpers';

export default {
  name: 'FhModal',
  props: {
    title: {
      type: String,
      required: false,
    },
    hasSmallPadding: {
      type: Boolean,
      default: false,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isMedium: {
      type: Boolean,
      default: false,
    },
    shouldCloseFromBackDrop: {
      type: Boolean,
      default: true,
    },
    customHeaderClass: {
      type: String,
      default: '',
    },
    modalOpenClass: {
      type: String,
      default: 'fh-modal-open',
    },
  },
  emits: ['close'],
  mounted() {
    document.body.classList.add(this.modalOpenClass);
    document.addEventListener('keydown', this.closeModalOnEscape);
    window.addEventListener('popstate', this.closeModalOnPopState);
    // For mobile viewports, we use the css variable --modal-mobile-h-unit to give height to the FhModal. The --modal-mobile-h-unit will
    // be used as the height's base unit (instead of the standard vh unit). This variable is set based on the window.innerHeight which
    // takes into account the browser's interface (e.g url bar). With this approach, we can have a full screen modal regardless of weather
    // the url bar is visible or not. In addition, we use the window's resize event to set the modal height and account for cases where
    // the screen is changing sizes (e.g user changes to landscape orientation on a mobile device).
    let screenHeightUnit = window.innerHeight * 0.01;
    setDocumentStyle('--modal-mobile-h-unit', `${screenHeightUnit}px`);
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('popstate', this.closeModalOnPopState);
    document.removeEventListener('keydown', this.closeModalOnEscape);
  },
  unmounted() {
    document.body.classList.remove(this.modalOpenClass);
  },
  computed: {
    animationClass() {
      return this.modalOpenClass === 'fh-modal-open' ? 'fh-modal-fade' : 'login-modal-fade';
    },
    headerClass() {
      if (this.customHeaderClass === '') {
        const paddingClass = this.hasSmallPadding ? 'fh-modal__header--p-sm' : '';
        return `fh-modal__header ${paddingClass}`;
      }
      return this.customHeaderClass;
    },
    bodyClass() {
      const paddingClass = this.hasSmallPadding ? 'fh-modal__body--p-sm' : '';
      return `fh-modal__body ${paddingClass}`;
    },
    hasHeader() {
      return this.$slots.header !== undefined;
    },
    hasBody() {
      return this.$slots.body !== undefined;
    },
    hasFooter() {
      return this.$slots.footer !== undefined;
    },
    sizeClass() {
      const smallClass = this.isSmall ? 'fh-modal--sm' : '';
      const mediumClass = this.isMedium ? 'fh-modal--md' : '';
      return `${smallClass} ${mediumClass}`;
    },
  },
  methods: {
    closeModalOnEscape(e) {
      if (!this.shouldCloseFromBackDrop) return;
      if (e.key === 'Escape') this.$emit('close');
    },
    closeModalOnPopState() {
      this.$emit('close');
    },
    onWindowResize: debounce(() => {
      let screenHeightUnit = window.innerHeight * 0.01;
      setDocumentStyle('--modal-mobile-h-unit', `${screenHeightUnit}px`);
    }, 200),
  },
};
</script>
