import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '@/logic/services/user/userService';
import { getConfigurationValue, getPageLanguage } from '../../helpers/utils';

const authServiceUrl = getConfigurationValue('authServiceUrl', '');
const userDataServiceUrl = getConfigurationValue('userServiceUrl', '');
const lang = getPageLanguage();

// Authentication
export const getUserAuthState = () => axiosGet(`${authServiceUrl}/users/auth-state`);
export const logoutUser = userUid => axiosPost(`${authServiceUrl}/users/logout`, { userUid });
export const updateUserToken = () => axiosPost(`${authServiceUrl}/users/update-token`);
export const deleteUserAccount = userUid => axiosPost(`${authServiceUrl}/users/delete`, { userUid });
export const signInGoogleUser = payload => axiosPost(`${authServiceUrl}/users/google`, payload);
export const signInFacebookUser = payload => axiosPost(`${authServiceUrl}/users/facebook`, payload);
export const signInAppleUser = payload => axiosPost(`${authServiceUrl}/users/apple`, payload);

// User data
export const getUserData = userUid => axiosGet(`${userDataServiceUrl}/users/${userUid}`);
export const updateUserContactDetails = (userUid, payload) => axiosPatch(`${userDataServiceUrl}/users/${userUid}/contact-details`, payload);
export const getUserTrips = userUid => axiosGet(`${userDataServiceUrl}/users/${userUid}/trips`, { lang });
export const getUserBookingDocument = (userUid, bookingCode) => axiosGet(`${userDataServiceUrl}/users/${userUid}/bookings/${bookingCode}/document`, { lang });
export const importUserBookingData = (userUid, payload) => axiosPost(`${userDataServiceUrl}/users/${userUid}/bookings/import`, payload);
export const deleteUserBooking = (userUid, bookingCode) => axiosDelete(`${userDataServiceUrl}/users/${userUid}/bookings/${bookingCode}`);
export const savePassengerDetails = (userUid, payload) => axiosPost(`${userDataServiceUrl}/users/${userUid}/passengers`, payload);
export const updatePassengerDetails = (userUid, passengerId, payload) => axiosPatch(`${userDataServiceUrl}/users/${userUid}/passengers/${passengerId}`, payload);
export const deletePassengerDetails = (userUid, passengerId) => axiosDelete(`${userDataServiceUrl}/users/${userUid}/passengers/${passengerId}`);
export const saveVehicleDetails = (userUid, payload) => axiosPost(`${userDataServiceUrl}/users/${userUid}/vehicles`, payload);
export const updateVehicleDetails = (userUid, vehicleId, payload) => axiosPatch(`${userDataServiceUrl}/users/${userUid}/vehicles/${vehicleId}`, payload);
export const deleteVehicleDetails = (userUid, vehicleId) => axiosDelete(`${userDataServiceUrl}/users/${userUid}/vehicles/${vehicleId}`);
