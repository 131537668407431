import { getConfigurationValue, getPageLanguage } from '@/logic/helpers/utils';
import { httpGet, httpPost } from '@/logic/services/httpService';
import { fetchWithRetry } from '../helpers/fetchWithRetry';

// fetch language as it will be required to collect proper data
let _lang = getPageLanguage();

// the microroot is fetched from environmental variables, and corresponds
// to our api endpoint, ex https://microapi.ferryhopper.com
let microRoot = getConfigurationValue('api', '');
let crsRoot = getConfigurationValue('crs', '');

//--------------------------------------------------------------------------
// gets the pickup spots for a set of locations and companies
export const fetchPickupSpots = (companies, locations) =>
  httpGet('/api/v1/crs/pickupspots', {
    lang: _lang,
    companies: companies,
    locations: locations
  }, true);

  

//--------------------------------------------------------------------------
export const fetchCRSItineraries = (origin, destination, date) => {
  let url = `${crsRoot}/api/indirect`;

  let data = {
    depStation: origin,
    arrStation: destination,
    depDate: date
  };

  return httpGet(url, data);
};

export const fetchCRSAlternativeDates = (origin, destination, date) => {
  return httpGet(`${crsRoot}/api/itineraries-calendar?depDate=${date}&depStation=${origin}&arrStation=${destination}&daysRange=3`)
}


//--------------------------------------------------------------------------
export const makeCombinedPricingRequest = pricingRequests => {
  let url = microRoot + 'ferry-providers/pricing';
  const pricingRequestCombined = {
    pricingRequests: pricingRequests
  };
  let data = {
    pricing: JSON.stringify(pricingRequestCombined),
    lang: getPageLanguage()
  };
  return httpPost(url, data);
};

/** Make pricing requests to api */
export const makeCombinedBookingIssueRequest = issueRequest =>
  httpPost(`/${_lang}/reservations/generate`, {
    issueRequest: JSON.stringify(issueRequest)
  });

/** Get active prices for a certain port on a certain month of a year */
export const getActivePrices = (origin, year, month) =>
  httpGet(
    `${crsRoot}/api/connections/active-prices/${origin}/${year}/${month}`,
    {}
  );

/** Fetch ports and regions from API */
export const fetchPortsAndRegions = () =>
  httpGet(`/api/v2/crs/fetch?ports=true&regions=true&lang=${_lang}`);

/** Fetch countries list from API */
export const fetchCountries = () =>
  httpGet(`/api/v2/crs/fetch?countriesList=true&lang=${_lang}`);

/** Validates loyalty card from API */
export const validateLoyaltyCard = payload =>
  httpPost(`${microRoot}ferry-providers/validate-loyalty-cards`, {
    loyaltyCards: {
      ...payload,
      lang: _lang
    }
  });


/** Validates residence discount from API */
export const validateResidenceDiscount = payload =>
  httpPost(`${microRoot}ferry-providers/validate-residence`, {
    residenceCodes: {
      ...payload,
      lang: _lang
    }
  });


/** Fetch on-demand CRS data with retry */
export const fetchOnDemandCRS = (extras) => fetchWithRetry(() => {
  const defaults = [];
  const queryString = [...defaults, ...extras].map(i => `${i}=true`).join('&');
  return httpGet(`/api/v2/crs/fetch?${queryString}&lang=${_lang}`);
})


/** Fetch custom crs data and execute a custom fallback */
export const initializeCustomCRS = (extras, initCallback) => fetchOnDemandCRS(extras || []).then(data => initCallback(data));

/** Post for repricing data */
export const makeRepriceRequest = (payload) => httpPost(`${microRoot}${_lang}/booking/get-updated-prices`, payload);

/** Post for extended trip info (e.g vessel details)*/
export const makeExtendedTripInfoRequest = (payload) => httpPost('/api/v2/crs/extended-trip-info', JSON.stringify(payload));

export const requestConfirmationEmailResend = (email) => httpPost(`${microRoot}api/confirmation-email/resend`, { email });
