import { latinPlatesMappings, letterToLatinMapping } from './letterMaps';
import { REGEX_ALL_NON_DIGITS, REGEX_ALL_NON_LATIN_AND_NON_DIGITS, REGEX_MATCH_ALL_WHITESPACES_DASHES_AND_PLUS, REGEX_MATCH_STARTING_PLUS_SIGN } from './regexes';
import { removeAllSpaces, trimAndUppercase } from './stringUtils';

/**
 * Maps a letter to a key=>value letterMap
 */
 const mapLetterToLetter = letterMap => letter => letterMap[letter] || letter;

 /**
  * Maps a latin character with special characters and accents to latin character.
  */
 const mapNonLatinToLatin = mapLetterToLetter(letterToLatinMapping);

/**
 * Maps a greek character to the equivalent for license plates
 */
 const mapGreekToPlates = mapLetterToLetter(latinPlatesMappings);

 
/**
 * Removes special characters and accents from a string
 * @param {string} input 
 */
export const latiniseString = (input) => {
    return input.replace(REGEX_ALL_NON_LATIN_AND_NON_DIGITS, mapNonLatinToLatin);
  };

  
/**
 * Converts non-canonical characters into canonical, and then
 * greek characters to english ones
 * @param {string} input
 */
export const sanitizeUserInput = input => {
  return trimAndUppercase(latiniseString(input));
};

/**
 * Maps locale-specific characters to english equivalents for plates
 * This function does not use ELOT casting (eg. ρ = > R) but
 * the image-based casting used in licence plates (ρ => P)
 * @param {string} input
 */
export const sanitizeLicensePlate = input => {
  // first, convert to plate characters to avoid canonicalization
  // eg. we do not want X (greek) to become CH, so we first convert it to X (english)
  return sanitizeUserInput(input.replace(REGEX_ALL_NON_LATIN_AND_NON_DIGITS, mapGreekToPlates));
};

export const sanitizeBookingName = name => {
  return sanitizeUserInput(name);
};


/**
 * Removes every character that is not a digit from a string
 * This function is used to format the phone strings used for ticket issuing
 * in order to avoid invalid characters on a phone number
 * @param {string} input 
 */
 export const sanitizePhone = (input) => {
    var numberOut = input.replace(REGEX_MATCH_ALL_WHITESPACES_DASHES_AND_PLUS, '').replace(REGEX_ALL_NON_DIGITS, '');
    return removeAllSpaces(numberOut);
  }
  
 /**
  * Sanitize phone with various business rules
  * @param {string} phone 
  */
 export const sanitizeBookingPhone = phone => {  
   // substitute + with 00 at the beginning
   let sanitizedPhone = sanitizePhone(phone.replace(REGEX_MATCH_STARTING_PLUS_SIGN, ''));
 
   const isFirstDigitZero = sanitizedPhone.charAt(0) === '0';
   const isSecondDigitZero = sanitizedPhone.charAt(1) === '0';

   if (!isFirstDigitZero)  return `00${sanitizedPhone}`;
   if (!isSecondDigitZero) return `0${sanitizedPhone}`;

   return sanitizedPhone;
 };