import axios from 'axios';

// User service instance properties
export const userService = axios.create({
  withCredentials: true
});

export const axiosGet = async (url, data = {}) =>
  await userService
    .get(url, { params: data })
    .then(response => response)
    .catch(error => error);

export const axiosPost = async (url, data = {}) =>
  await userService
    .post(url, data)
    .then(response => response)
    .catch(error => error);

export const axiosPatch = async (url, data = {}) =>
  await userService
    .patch(url, data)
    .then(response => response)
    .catch(error => error);

export const axiosDelete = async url =>
  await userService
    .delete(url)
    .then(response => response)
    .catch(error => error);
