<template>
  <div class="booking-flow-grid">
    <div class="booking-flow-grid__col">
      <slot name="col"></slot>
    </div>
    <div :class="['booking-flow-grid__col-sm', { mb20: isMobile, 'booking-flow-grid__col-sm--reverse': reverse }]">
      <slot name="col-sm"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingFlowGrid',
  props: {
    reverse: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/booking-flow-grid.scss';
</style>
