import * as types from '../mutation-types';

export const validationModule = {
  namespaced: true,
  state: {
    errors: [],
  },
  mutations: {
    [types.ADD_ERROR](state, error) {
      if (!state.errors.find((err) => err.name === error.name)) state.errors.push(error);
    },
    [types.REMOVE_ERROR](state, error) {
      state.errors = state.errors.filter((err) => err.name !== error.name);
    },
    [types.CLEAR_ERRORS](state) {
      state.errors = [];
    },
  },
  actions: {
    addError({ commit }, error) {
      commit(types.ADD_ERROR, error);
    },
    removeError({ commit }, error) {
      commit(types.REMOVE_ERROR, error);
    },
    clearErrors({ commit }) {
      commit(types.CLEAR_ERRORS);
    },
  },
};
