/**
 * Mutates a property of an object, only if the property exists and the new value
 * does not alter the property type
 * 
 * @param {any} obj 
 * @param {any} param1 
 */
export const mutateExistingProp = (obj, { propName, propValue }) => {
    if ((obj[propName] !== undefined)  && (typeof obj[propName] === typeof propValue)) {
        mutateProp(obj, { propName, propValue });
    }
}

/**
 * Mutates a property of an object even if the property does not originally exist
 * 
 * @param {any} obj 
 * @param {any} param1 
 */
export const mutateProp = (obj, { propName, propValue }) => {
    obj[propName] = propValue;
}

/** 
 * Merges properties to a target object
 */
 export const updateObjectWithProp = (obj, key, value) => ({ ...obj, [key]: value });