// DATA MODULE
export const SET_COUNTRIES = 'SET_COUNTRIES';

// TICKET CONFIGURATION MODULE
export const SET_IS_WAITING_FOR_PRICES = 'SET_IS_WAITING_FOR_PRICES';
export const SET_HAS_NETWORK_ERROR = 'SET_HAS_NETWORK_ERROR';
export const RESET_PRICING_ERRORS = 'RESET_PRICING_ERRORS';
export const RESET_PRICING_STATE = 'RESET_PRICING_STATE';
export const SET_TRIPS_FOR_SEATING = 'SET_TRIPS_FOR_SEATING';
export const UPDATE_TRIPS_FOR_SEATING = 'UPDATE_TRIPS_FOR_SEATING';
export const SET_EARLY_BOOKING = 'SET_EARLY_BOOKING';
export const ADD_PET = 'ADD_PET';
export const REMOVE_PET = 'REMOVE_PET';
export const CHANGE_PET_TYPE = 'CHANGE_PET_TYPE';
export const CHANGE_PET_ACCOMMODATION = 'CHANGE_PET_ACCOMMODATION';
export const CHANGE_PET_OWNER = 'CHANGE_PET_OWNER';
export const ADD_PASSENGER = 'ADD_PASSENGER';
export const REMOVE_PASSENGER = 'REMOVE_PASSENGER';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE';
export const CHANGE_VEHICLE_PROPERTY = 'CHANGE_VEHICLE_PROPERTY';
export const REGROUP_TRIP_SELECTIONS = 'REGROUP_TRIP_SELECTIONS';
export const RESET_SEAT_SELECTION = 'RESET_SEAT_SELECTION';
export const RESET_SEAT_SELECTION_MESSAGE = 'RESET_SEAT_SELECTION_MESSAGE';
export const CHANGE_PASSENGER_SEAT = 'CHANGE_PASSENGER_SEAT';
export const CHANGE_PASSENGER_RESIDENCE_DISCOUNT = 'CHANGE_PASSENGER_RESIDENCE_DISCOUNT';
export const CHANGE_PASSENGER_LOYALTY_NUMBER = 'CHANGE_PASSENGER_LOYALTY_NUMBER';
export const SET_SEAT_SELECTION_MESSAGE = 'SET_SEAT_SELECTION_MESSAGE';
export const CHANGE_PASSENGER_PROPERTY = 'CHANGE_PASSENGER_PROPERTY';
export const CHANGE_TRIP_PROPERTY = 'CHANGE_TRIP_PROPERTY';
export const CHANGE_VEHICLE_DRIVER = 'CHANGE_VEHICLE_DRIVER';

// BOOKING MODULE
export const SET_FINAL_TRIP_REQUESTS_COMBINATIONS = 'SET_FINAL_TRIP_REQUESTS_COMBINATIONS';
export const SET_FINAL_SELECTED_SPECIAL_OFFERS = 'SET_FINAL_SELECTED_SPECIAL_OFFERS';
export const SET_TRIPS_FOR_BOOKING = 'SET_TRIPS_FOR_BOOKING';
export const PREPARE_TRIPS_PRICING = 'PREPARE_TRIPS_PRICING';
export const PREPARE_TRIPS_PRICING_COMBINATIONS = 'PREPARE_TRIPS_PRICING_COMBINATIONS';
export const SET_TRIPS_WITH_COUPONS = 'SET_TRIPS_WITH_COUPONS';
export const SET_REPRICING_DATA = 'SET_REPRICING_DATA';
export const SET_COUPON_VALIDATION = 'SET_COUPON_VALIDATION';
export const SET_COUPON_APPLIED = 'SET_COUPON_APPLIED';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';
export const SET_REFUND_PROTECT_ERROR = 'SET_REFUND_PROTECT_ERROR';
export const SET_REFUND_PROTECT_SELECTED = 'SET_REFUND_PROTECT_SELECTED';
export const SET_TRAVEL_INSURANCE_ERROR = 'SET_TRAVEL_INSURANCE_ERROR';
export const SET_TRAVEL_INSURANCE_SELECTED = 'SET_TRAVEL_INSURANCE_SELECTED';
export const SET_SUPPORT_PLUS_ERROR = 'SET_SUPPORT_PLUS_ERROR';
export const SET_SUPPORT_PLUS_SELECTED = 'SET_SUPPORT_PLUS_SELECTED';
export const SET_FLEXI_ERROR = 'SET_FLEXI_ERROR';
export const SET_FLEXI_SELECTED = 'SET_FLEXI_SELECTED';

// UI MODULE
export const SET_IS_FETCHING_FOR_PRICES = 'SET_IS_FETCHING_FOR_PRICES';
export const SET_COUPON_INPUT_VISIBILITY = 'SET_COUPON_INPUT_VISIBILITY';
export const SET_IS_LOADING = 'SET_IS_LOADING';

// INVOICE MODULE
export const SET_INVOICE_SELECTED = 'SET_INVOICE_SELECTED';
export const SET_INVOICE_DETAILS = 'SET_INVOICE_DETAILS';

// USER MODULE
export const SET_USER = 'SET_USER';
export const SET_SHOW_SIGNIN_SUCCESS = 'SET_SHOW_SIGNIN_SUCCESS';
export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';
export const SET_USER_UPDATE_SUCCESS = 'SET_USER_UPDATE_SUCCESS';
export const SET_USER_AUTH_STATE = 'SET_USER_AUTH_STATE';
export const SET_PATCH_CONTACT_DETAILS_ERROR = 'SET_PATCH_CONTACT_DETAILS_ERROR';
export const SET_BOOKING_IMPORT_ERROR = 'SET_BOOKING_IMPORT_ERROR';
export const SET_BOOKING_DELETE_ERROR = 'SET_BOOKING_DELETE_ERROR';
export const SET_USER_TRIPS = 'SET_USER_TRIPS';
export const SET_USER_BOOKING = 'SET_USER_BOOKING';
export const SET_CRUD_ERROR = 'SET_CRUD_ERROR';
export const SET_USER_DETAILS_ERROR = 'SET_USER_DETAILS_ERROR';
export const SET_HAS_UPDATED_PASSENGER = 'SET_HAS_UPDATED_PASSENGER';
export const SET_HAS_ADDED_PASSENGER = 'SET_HAS_ADDED_PASSENGER';
export const SET_HAS_UPDATED_VEHICLE = 'SET_HAS_UPDATED_VEHICLE';
export const SET_HAS_ADDED_VEHICLE = 'SET_HAS_ADDED_VEHICLE';
export const SET_HAS_DELETED_VEHICLE = 'SET_HAS_DELETED_VEHICLE';
export const SET_HAS_DELETED_PASSENGER = 'SET_HAS_DELETED_PASSENGER';
export const SET_BOOKING_DOCUMENT_ERROR = 'SET_BOOKING_DOCUMENT_ERROR';
export const SET_ACCOUNT_DELETE_ERROR = 'SET_ACCOUNT_DELETE_ERROR';

// VALIDATION MODULE
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
