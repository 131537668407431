import { getPageLanguage, message } from '@/logic/helpers/utils'
import { logException } from './events/errorLogging';

export default {

  /**
   * Get the translation string corresponding to a specific error code, based on the current language
   * @param {String} code 
   */
  get: function(code) {
    const translationkey = `flowErrors.${code}`;
    const translation = message(translationkey);
    
    if (translation !== translationkey) {
      return translation;   
    }
        
    // remote exception loging
    logException('GenericErrorCodes', {
      lang: getPageLanguage(),
      key: translationkey
    });
    return message('flowErrors.generic'); 
  }
};
