import {
  VALIDATION_RULES,
  satisfiesRequired,
  satisfiesMinLength,
  satisfiesMaxLength,
  satisfiesEmail,
  satisfiesNumeric,
  satisfiesLength,
  satisfiesCustomPhone,
  satisfiesNumericAllowDashes,
  satisfiesCustomLatinText,
  satisfiesCustomBirthdate,
  satisfiesCustomVehiclePlate,
  satisfiesCustomDocumentExpiration,
  satisiesNoSpecialCharacters,
} from './validationRules';
import {
  getSuccessfulStatus,
  getFailedStatusForRequired,
  getFailedStatusForMin,
  getFailedStatusForMax,
  getFailedStatusForEmail,
  getFailedStatusForNumeric,
  getFailedStatusForLength,
  getFailedStatusForCustomPhone,
  getFailedStatusForNumericAllowDashes,
  getFailedStatusForCustomLatinText,
  getFailedStatusForCustomBirthdate,
  getFailedStatusForCustomVehiclePlate,
  getFailedStatusForCustomDocumentExpiration,
  getFailedStatusForNoSpecialCharacters,
} from './validationStatuses';
import { message } from '@/logic/helpers/utils';

export const getValidationStatusForInput = (input, rules) => {
  let validationsStatus;

  for (const { ruleName, ruleValue } of getUnpackedValidationRules(rules)) {
    if (ruleName === VALIDATION_RULES.REQUIRED && input === message('generic.dateFormat')) {
      validationsStatus = getFailedStatusForRequired();
      break;
    }

    if (ruleName === VALIDATION_RULES.REQUIRED && !satisfiesRequired(input)) {
      validationsStatus = getFailedStatusForRequired();
      break;
    }

    if (ruleName === VALIDATION_RULES.MIN && input !== '' && !satisfiesMinLength(input, ruleValue)) {
      validationsStatus = getFailedStatusForMin(ruleValue);
      break;
    }

    if (ruleName === VALIDATION_RULES.MAX && input !== '' && !satisfiesMaxLength(input, ruleValue)) {
      validationsStatus = getFailedStatusForMax(ruleValue);
      break;
    }

    if (ruleName === VALIDATION_RULES.EMAIL && !satisfiesEmail(input)) {
      validationsStatus = getFailedStatusForEmail();
      break;
    }

    if (ruleName === VALIDATION_RULES.NUMERIC && !satisfiesNumeric(input)) {
      validationsStatus = getFailedStatusForNumeric();
      break;
    }

    if (ruleName === VALIDATION_RULES.LENGTH && !satisfiesLength(input, ruleValue)) {
      validationsStatus = getFailedStatusForLength(ruleValue);
      break;
    }

    if (ruleName === VALIDATION_RULES.NUMERIC_ALLOW_DASHES && input !== '' && !satisfiesNumericAllowDashes(input)) {
      validationsStatus = getFailedStatusForNumericAllowDashes();
      break;
    }

    if (ruleName === VALIDATION_RULES.CUSTOM_PHONE && input !== '' && !satisfiesCustomPhone(input)) {
      validationsStatus = getFailedStatusForCustomPhone();
      break;
    }

    if (ruleName === VALIDATION_RULES.CUSTOM_LATIN_TEXT && !satisfiesCustomLatinText(input)) {
      validationsStatus = getFailedStatusForCustomLatinText();
      break;
    }

    if (ruleName === VALIDATION_RULES.CUSTOM_BIRTHDATE && input !== '' && input !== message('generic.dateFormat') && !satisfiesCustomBirthdate(input)) {
      validationsStatus = getFailedStatusForCustomBirthdate();
      break;
    }

    if (ruleName === VALIDATION_RULES.CUSTOM_VEHICLE_PLATE && !satisfiesCustomVehiclePlate(input)) {
      validationsStatus = getFailedStatusForCustomVehiclePlate();
      break;
    }

    if (ruleName === VALIDATION_RULES.CUSTOM_DOCUMENT_EXPIRATION && input !== '' && input !== message('generic.dateFormat') && !satisfiesCustomDocumentExpiration(input)) {
      validationsStatus = getFailedStatusForCustomDocumentExpiration();
      break;
    }

    if (ruleName === VALIDATION_RULES.NO_SPECIAL_CHARACTERS && !satisiesNoSpecialCharacters(input)) {
      validationsStatus = getFailedStatusForNoSpecialCharacters();
      break;
    }

    validationsStatus = getSuccessfulStatus();
  }

  return validationsStatus;
};

const getUnpackedValidationRules = (rules) =>
  rules
    .split('|')
    .map((rule) => rule.split(':'))
    .map((rule) => ({ ruleName: rule[0], ruleValue: rule[1] }));
