import { getConfigurationValue } from '@/logic/helpers/utils';

let cdn = getConfigurationValue('cdn', 'https://images.ferryhopper.com/');

const boardingMethodsIcons = {
  BOARDING_METHOD_PAPER_COUPON: `${cdn}confirmation/BM-PaperTickets.png`,
  BOARDING_METHOD_ETICKET: `${cdn}confirmation/CP-MobApp.png`,
  BOARDING_METHOD_BOARDING_PASS: `${cdn}confirmation/CP-MobApp.png`,
  BOARDING_METHOD_CONFIRMATION_EMAIL: `${cdn}confirmation/BM-ConfEmail.png`,
  BOARDING_METHOD_ID_BOARDING: `${cdn}confirmation/BM-IDCard.png`,
  BOARDING_METHOD_BOARDING_PASS_KIOSK: `${cdn}confirmation/BM-BoardingPassKiosk.png`,
  BOARDING_METHOD_PAPER_COUPONS_KIOSK: `${cdn}confirmation/BM-PaperTickets.png`,
  BOARDING_METHOD_OPERATOR_EMAIL: `${cdn}confirmation/BM-OperatorEmail.png`,
  BOARDING_METHOD_PRINTED_BOARDING_PASS: `${cdn}confirmation/BM-PrintBoardingPass.png`,
  BOARDING_METHOD_PRINTED_ETICKET: `${cdn}confirmation/BM-PrintOnline.png`
};

export const getΒoardingMethodIcon = method => boardingMethodsIcons[method];
