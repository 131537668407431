<template>
  <button
    data-test="fhCta"
    :class="['btn ladda-button fh-cta', btnFlavorClass, btnWidthClass, btnStateClass]"
    :id="id"
    data-style="zoom-in"
    data-spinner-size="40"
    data-spinner-color="#FFF"
    data-spinner-lines="18"
    @click="handleClick"
  >
    <slot></slot>
    <i v-if="showArrow" class="fh fh-right-arrow pl15" aria-hidden="true"></i>
  </button>
</template>

<script>
import * as Ladda from 'ladda';
import { uniqueId } from 'lodash-es';

export default {
  name: 'FhCta',
  props: {
    disabled: Boolean,
    btnFlavorClass: {
      type: String,
      default: 'btn-primary'
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    isLadda: {
      type: Boolean,
      default: false
    },
    isWaiting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: `ladda-${uniqueId(15)}`,
      laddaButton: undefined
    };
  },
  emits: ['click'],
  mounted() {
    if (this.isLadda) this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
  },
  beforeUnmount() {
    if (this.isLadda) this.laddaButton.remove();
  },
  computed: {
    btnStateClass() {
      const disabledClass = this.disabled ? 'disabled' : '';
      const waitingClass = this.isWaiting ? 'waiting' : '';
      return `${disabledClass} ${waitingClass}`;
    },
    btnWidthClass() {
      return this.isFullWidth ? 'btn--full-width' : ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  },
  watch: {
    isWaiting(newValue) {
      if (!this.isLadda) return;
      if (newValue) {
        this.laddaButton.start();
      } else {
        this.laddaButton.stop();
      }
    },
    id() {
      this.laddaButton = Ladda.create(document.querySelector(`#${this.id}`));
    }
  }
};
</script>

<style lang="scss">
@import './fh-cta';
</style>
