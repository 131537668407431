import GenericErrorCodes from "../services/GenericErrorCodes";

/**
 * Handles a promise with error catching, thus allowing us to use await
 */
export const handlePromise = promise => {
  return promise
    .then(data => ({ response: data, error: undefined }))
    .catch(error => { 
      if (!error) return Promise.resolve({ response: undefined, error: { message: GenericErrorCodes.get('generic') } });
      return Promise.resolve({ response: undefined, error });
    });
};
