// Succesful requests
export const isStatusSuccess = status => status === 200 || status === 201;

// Client errors
export const isStatusBadRequest = status => status === 400;
export const isStatusUnauthorized = status => status === 401;
export const isStatusForbidden = status => status === 403;
export const isStatusNotFound = status => status === 404;

// Server errors
export const isServerError = status => status >= 500;

// Network offline
export const isStatusOffline = status => !status;
export const isStatusCreated = status => status === 201;
