<template>
  <div class="journey-title" :class="[classlist, { 'journey-title--wrap': cropText === false }]">
    <template v-for="(port, index) in ports" :key="`journey-title-${index}`">
      <span :class="{ 'journey-title__location--crop': cropText }">{{ port }}</span>
       <i v-if="index < ports.length - 1" class="fh fh-arrow journey-arrow px4" :class="paddingClass" :key="`arrow-${index}`"></i>
    </template>
  </div>
</template>

<script>
export default {
  name: 'JourneyTitle',
  props: {
    ports: {
      type: Array,
      default: function() {
        return [];
      }
    },
    classlist: {
      type: String,
      default: '',
    },
    cropText: {
      type: Boolean,
      default: true,
    },
    paddingClass: {
      type: String,
      default: '',
    },
  },
};
</script>