<template>
  <div data-test="toggleSwitch" :class="['toggle-switch noselect', { 'toggle-switch--fixed-height': hasFixedHeight, 'toggle-switch--disabled': disabled }]" @click.prevent="$emit('input', !value)">
    <label class="toggle-switch__label">
      <input class="toggle-switch__checkbox" type="checkbox" :checked="value" />
      <span :class="sliderClass"></span>
    </label>
    <span class="toggle-switch__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    hasFixedHeight: {
      type: Boolean,
      default: true
    },
    isDark: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  computed: {
    sliderClass() {
      const colorClass = this.isDark ? 'toggle-switch__slider--dark' : 'toggle-switch__slider--light';
      return `toggle-switch__slider ${colorClass}`;
    }
  }
};
</script>
