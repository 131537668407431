import dayjs from '@/logic/services/date/dateService';
import { isNull } from './utils';
import { message } from '@/logic/helpers/utils';

/**
 * constants
 */
export const DATE_FORMAT = 'DD/MM/YYYY';

export const LOCALISED_DATE_FORMAT = message('generic.dateFormat');

let DATE_REGEX = /^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/;
let MIN_BIRTH_DATE = '01/01/1910';
let MAX_EXP_DATE = '01/01/2070';

export const getToday = () => dayjs();

export const getYesterday = () => getDayBefore();

/**
 * Returns true if a date is before today
 * @param {Object} date A dayjs Object
 * @param {String} unit A unit that the comparison must respect. A list of available units can be found here: https://day.js.org/docs/en/manipulate/start-of#list-of-all-available-units
 * @returns {boolean}
 */
export const dateIsBeforeToday = (date) => date.isBefore(dayjs(), 'day');

/**
 * @param {Object} date
 * @returns the day before a given day, or yesterday, in case the given day is undefined
 */
export const getDayBefore = (date) => {
  if (!date) return getToday().subtract(1, 'days');
  else return date.clone().subtract(1, 'days');
};

export const getNextDay = (date) => {
  if (!date) return getToday().add(1, 'days');
  return date.clone().add(1, 'days');
};

/**
 * Some dayjs instances, precalculated for performance
 */
let TODAY = getToday();
let BIRTHDAY_MIN = dayjs(MIN_BIRTH_DATE, DATE_FORMAT);
let EXPDAY_MAX = dayjs(MAX_EXP_DATE, DATE_FORMAT);

/**
 * Date validation in our format
 * @param {string} value
 */
export const isValidDateFormat = (value) => {
  return DATE_REGEX.test(value);
};

/**
 * Date validation from dayjs
 * @param {string} value
 */
const isValidDayjsDate = (value) => {
  return dayjs(value, DATE_FORMAT).isValid();
};

export const durationInMinutes = (d1, d2) => {
  return dayjs.duration(d2.diff(d1)).asMinutes();
};

/**
 * Returns true if a given string date is of a valid format
 */
export const isValidDate = (value) => {
  return isValidDateFormat(value) && isValidDayjsDate(value);
};

/**
 * Returns true if a date is within a valid range for being a birthdate
 */
export const isValidBirthdateRange = (dateString) => {
  let birthDate = dayjs(dateString, DATE_FORMAT);
  return birthDate.isValid() && birthDate.isAfter(BIRTHDAY_MIN) && birthDate.isBefore(TODAY);
};

/**
 * Returns true if a date is within a valid range for being a document expiration
 */
export const isValidExpirationRange = (dateString) => {
  let expDate = dayjs(dateString, DATE_FORMAT);
  return expDate.isValid() && expDate.isAfter(TODAY) && expDate.isBefore(EXPDAY_MAX);
};

export const isValidBirthDate = (dateString) => {
  return isValidDate(dateString) && isValidBirthdateRange(dateString);
};

export const isValidExpDate = (dateString) => {
  return isValidDate(dateString) && isValidExpirationRange(dateString);
};

export const isBeforeToday = (date) => date.isBefore(TODAY, 'days');

export const isToday = (date) => date.isSame(TODAY, 'days');

export const isDatesOrderInvalid = (dates) => {
  let hasInvalidDateOrder = false;
  dates.reduce((previous, current) => {
    if (isNull(previous) || isNull(current)) return current;
    hasInvalidDateOrder = hasInvalidDateOrder || current.isBefore(previous, 'days');
    return current;
  }, null);
  return hasInvalidDateOrder;
};

const DATE_FORMATS = {
  // format for visualizing itinerary times
  tripTime: 'HH:mm',
  // short date appearing in cart and input fields for dates
  tripDateShort: 'dddd DD/MM',
  // country specific date representations
  // tripDateShort is currently used in:
  // - Trip header: Results section
  // - Trip header: Cart
  tripDateShortEL: 'ddd DD MMM.',
  tripDateShortEN: 'ddd DD MMM',
  // tripDateLong is currently used in:
  // - Booking summary: Booking Tab
  tripDateLongEL: 'dddd, DD MMMM',
  tripDateLongEN: 'ddd, DD MMMM',
  isoDate: 'YYYY-MM-DD',
  isoTime: 'HH:mm',
};

export const getTripTimeFormat = () => {
  return DATE_FORMATS.tripTime;
};

export const getShortDateFormat = (locale) => {
  if (!locale) return DATE_FORMATS.tripDateShort;
  return DATE_FORMATS[`tripDateShort${locale.toUpperCase()}`] || DATE_FORMATS[`tripDateShortEN`];
};

export const getLongDateFormat = (locale) => {
  return DATE_FORMATS[`tripDateLong${locale.toUpperCase()}`] || DATE_FORMATS[`tripDateLongEN`];
};

export const findClosestPrevActiveDate = (altDates, active) => {
  const activeDate = active;
  const previousDates = altDates.filter((altDate) => altDate.isBefore(activeDate, 'days'));
  const sortedPreviousDates = previousDates.sort((a, b) => b - a);
  return sortedPreviousDates[0] || null;
};

export const findClosestAfterActiveDate = (altDates, active) => {
  const activeDate = active;
  const afterDates = altDates.filter((altDate) => activeDate.isBefore(altDate, 'days'));
  const sortedAfterDates = afterDates.sort((a, b) => a - b);
  return sortedAfterDates[0] || null;
};

export const dayjsToEnforcedUtcISO86 = (inputDate) => {
  let dayjsDate = dayjs(inputDate);
  return `${dayjsDate.format(DATE_FORMATS.isoDate)}T${dayjsDate.format(DATE_FORMATS.isoTime)}:00+00:00`;
};

export const dateStringToUtc = (dateString) => dayjs(dateString).utc();
