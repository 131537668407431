import { EMAIL_REGEX, NUMERIC_REGEX, NUMERIC_ALLOW_DASHES_REGEX, PHONE_VALIDATION_REGEX, LATIN_ALPHA_SPACES, LATIN_ALPHA_SPACES_NUM, REGEX_ALPHA_NUM_NO_SPECIAL_CHARACTERS } from '@/logic/helpers/regexes';
import { sanitizePhone } from '@/logic/helpers/sanitizers';
import { isValidBirthDate, isValidExpDate } from '@/logic/helpers/dateUtils';

export const VALIDATION_RULES = {
  REQUIRED: 'required',
  MIN: 'min',
  MAX: 'max',
  EMAIL: 'email',
  NUMERIC: 'numeric',
  LENGTH: 'length',
  NUMERIC_ALLOW_DASHES: 'numericAllowDashes',
  CUSTOM_PHONE: 'customPhone',
  CUSTOM_LATIN_TEXT: 'customLatinText',
  CUSTOM_BIRTHDATE: 'customBirthdate',
  CUSTOM_VEHICLE_PLATE: 'customVehiclePlate',
  CUSTOM_DOCUMENT_EXPIRATION: 'customDocumentExpiration',
  NO_SPECIAL_CHARACTERS: 'noSpecialCharacters',
};

export const satisfiesRequired = (value) => value && value.trim();

export const satisfiesMinLength = (value, length) => value.length >= length;

export const satisfiesMaxLength = (value, length) => value.length <= length;

export const satisfiesEmail = (value) => EMAIL_REGEX.test(value);

export const satisfiesNumeric = (value) => NUMERIC_REGEX.test(value);

export const satisfiesLength = (value, length) => value.length === Number(length);

export const satisfiesNumericAllowDashes = (value) => NUMERIC_ALLOW_DASHES_REGEX.test(value);

export const satisfiesCustomLatinText = (value) => LATIN_ALPHA_SPACES.test(value);

export const satisfiesCustomPhone = (value) => PHONE_VALIDATION_REGEX.test(value) && sanitizedPhoneValidation(value);

export const satisfiesCustomBirthdate = (value) => isValidBirthDate(value);

export const satisfiesCustomVehiclePlate = (value) => LATIN_ALPHA_SPACES_NUM.test(value);

export const satisfiesCustomDocumentExpiration = (value) => isValidExpDate(value);

export const satisiesNoSpecialCharacters = (value) => !REGEX_ALPHA_NUM_NO_SPECIAL_CHARACTERS.test(value);

const sanitizedPhoneValidation = (value) => sanitizePhone(value).length >= 8 && sanitizePhone(value).length <= 13;
