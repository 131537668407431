/**
 * Takes a hash, then takes a `HTMLElement` and replaces its `hash` property, if it is different.
 * @param {string} hash
 * @returns {( el: HTMLElement ) => HTMLElement}
 */
export const replaceHash = hash => el => {
  if (el.hash === hash) return; // Return early to avoid touching DOM elements if unnecessary.
  el.hash = hash;
};

/**
 * Takes an array of elements and a hash and replaces each element's `hash` property with the input hash.
 * @param {HTMLElement[]} anchors
 * @param {string} hash
 */
export const replaceHashAll = (anchors, hash) => anchors.forEach(replaceHash(hash));

/**
 * Sets a property in the root element of the document
 * @param {string} property
 * @param {string} value
 */
export const setDocumentStyle = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};
