import { message } from '@/logic/helpers/utils';
import GenericErrorCodes from '@/logic/services/GenericErrorCodes';

export const getSuccessfulStatus = () => ({ status: true, message: '' });
export const getFailedStatusForRequired = () => ({ status: false, message: message('generic.error.requiredField') });
export const getFailedStatusForMin = (length) => ({ status: false, message: `${message('generic.error.minimumCharacters.text1')} ${length} ${message('generic.error.minimumCharacters.text2')}` });
export const getFailedStatusForMax = (length) => ({ status: false, message: `${message('generic.error.maximumCharacters.text1')} ${length} ${message('generic.error.maximumCharacters.text2')}` });
export const getFailedStatusForEmail = () => ({ status: false, message: message('generic.error.emailNotValid') });
export const getFailedStatusForNumeric = () => ({ status: false, message: message('generic.error.onlyNumbersAllowed') });
export const getFailedStatusForLength = (length) => ({ status: false, message: `${message('generic.error.fixedNumOfCharacters.text1')} ${length} ${message('generic.error.fixedNumOfCharacters.text2')}` });
export const getFailedStatusForCustomPhone = () => ({ status: false, message: message('generic.error.invalidPhoneNum') });
export const getFailedStatusForNumericAllowDashes = () => ({ status: false, message: message('generic.error.onlyNumbersAllowed') });
export const getFailedStatusForCustomLatinText = () => ({ status: false, message: GenericErrorCodes.get('validations.alphaSpaceOnly') });
export const getFailedStatusForCustomBirthdate = () => ({ status: false, message: message('generic.invalidBirthdate') });
export const getFailedStatusForCustomVehiclePlate = () => ({ status: false, message: GenericErrorCodes.get('validations.alphaNumSpaceOnly') });
export const getFailedStatusForCustomDocumentExpiration = () => ({ status: false, message: message('generic.invalidExpDate') });
export const getFailedStatusForNoSpecialCharacters = () => ({ status: false, message: message('generic.error.someSpecialCharactersNotAllowed') });
