<template>
  <div :class="$style.fhInput" :id="id" data-test="fhInput">
    <input
      :disabled="disabled"
      :class="mainClass"
      :placeholder="placeholder"
      :value="modelValue"
      :type="type"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
    />
    <i v-if="iconClass !== ''" :class="[iconClass, $style.fhInput__icon]"></i>
    <i v-else-if="errorMessage !== ''" :class="['fh fh-notification text-danger', $style.fhInput__icon]"></i>
    <span v-if="errorMessage !== '' && shouldShowError" class="text-error">{{ errorMessage }}</span>
  </div>
</template>

<script>
import emitter from '@/emitter';
import { mapState, mapActions } from 'vuex';
import { getValidationStatusForInput } from '@/logic/validation/validation';

export default {
  name: 'FhInput',
  props: {
    type: { String, default: 'text' },
    name: { type: String, required: true },
    id: { type: String, default: '' },
    modelValue: { type: String, required: true },
    placeholder: { type: String, default: '' },
    rules: { type: String, default: '' },
    shouldShowError: { type: Boolean, default: true },
    sanitizingFunction: { type: Function },
    autocomplete: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    maxlength: { type: Number, default: null },
    iconClass: { type: String, default: '' },
    customErrorMessage: { type: String, default: '' },
    shouldValidateOnEmitter: { type: Boolean, default: true },
  },
  emits: ['update:modelValue'],
  beforeUnmount() {
    emitter.$off('validate', this.validate);
    this.removeError({ name: this.name });
  },
  created() {
    emitter.$on('validate', this.validate);
    emitter.$on('validateField', (fieldName) => {
      if (fieldName === this.name) this.validate();
    });
  },
  computed: {
    ...mapState({
      errors: (state) => state.validationModule.errors,
    }),
    errorMessage() {
      const error = this.errors.find((error) => error.name === this.name) || { message: this.customErrorMessage };
      return error ? error.message : '';
    },
    mainClass() {
      const disabledClass = this.disabled ? this.$style['fhInput__input--disabled'] : '';
      const errorClass = this.errorMessage !== '' ? this.$style['fhInput__input--has-error'] : '';
      let cobrandedClass = this.globalCobrandedVariant === 'easyferry' ? this.$style['fhInput__input--round-border'] : '';
      return `${this.$style.fhInput__input} ${disabledClass} ${errorClass} ${cobrandedClass}`;
    },
    shouldSanitize() {
      return typeof this.sanitizingFunction === 'function';
    },
  },
  methods: {
    ...mapActions({
      addError: 'validationModule/addError',
      removeError: 'validationModule/removeError',
    }),
    handleInput($event) {
      if (this.shouldSanitize && $event.target.value !== '') {
        this.$emit('update:modelValue', this.sanitizingFunction($event.target.value));
        return;
      }
      this.$emit('update:modelValue', $event.target.value);
    },
    handleFocus() {
      this.removeError({ name: this.name });
    },
    handleBlur() {
      this.validate();
    },
    validate() {
      if (!this.shouldValidateOnEmitter) return;
      const validationStatus = getValidationStatusForInput(this.modelValue, this.rules);
      if (!validationStatus.status) this.addError({ name: this.name, message: validationStatus.message });
      else this.removeError({ name: this.name });
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage !== '') emitter.$emit('onValidationErrorShown', { name: this.name, value: this.modelValue, error: this.errorMessage });
    },
  },
};
</script>

<style module lang="scss">
@import './fh-input.scss';
</style>
